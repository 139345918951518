<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <input
              v-model="typeform.name"
              type="text"
              class="form-control"
              name="name"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Apellido</label>
            <input
              v-model="typeform.lastName"
              type="text"
              class="form-control"
              name="lastName"
              :class="{
                'is-invalid': typesubmit && $v.typeform.lastName.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.lastName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.lastName.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre de usuario</label>
            <input
              v-model="typeform.userName"
              type="text"
              class="form-control"
              name="userName"
              :class="{
                'is-invalid': typesubmit && $v.typeform.userName.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.userName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.userName.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label> Cliente</label>
            <div>
              <select
                v-model="typeform.client"
                name="client"
                class="form-select"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.client.$error,
                }"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in clients"
                  :key="index"
                  :value="item._id"
                  :selected="typeform.client == item._id"
                >
                  {{ item.name }}
                </option>
              </select>
              <div
                v-if="typesubmit && $v.typeform.client.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.client.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!typeform._id">
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Correo Electronico</label>
            <div>
              <input
                v-model="typeform.email"
                type="email"
                name="email"
                class="form-control"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.email.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.email.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.email.required">
                  Este campo es requerido.
                </span>
                <span v-if="!$v.typeform.email.email">
                  Ingrese un correo valido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Contraseña</label>
            <div>
              <input
                v-model="typeform.password"
                type="password"
                name="password"
                class="form-control"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.password.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.password.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.password.required">
                  Este campo es requerido.
                </span>
                <span v-if="!$v.typeform.password.minLength">
                  La contraseña tiene que tener al menos 6 caracteres.
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mb-0">
        <div>
          <button type="submit" class="btn btn-primary">Guardar</button>
          <button
            @click="$emit('closeModa')"
            type="button"
            class="btn btn-secondary ms-1"
          >
            Cancelar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clients: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      lastName: {
        required,
      },
      userName: {
        required,
      },
      client: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('error');
      } else {
        if (this.typeform._id) {
          this.$http
            .put(`users/${this.typeform._id}`, this.typeform)
            .then(() => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Usuario guardado con exito',
                showConfirmButton: false,
                timer: 1500,
              });

              this.$emit('closeModa');
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$http
            .post('users', this.typeform)
            .then(() => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Usuario registrado con exito',
                showConfirmButton: false,
                timer: 1500,
              });

              this.$emit('closeModa');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>
